body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
footer{
  text-align: center;
  user-select: none;
  position: absolute;
  width: 100%;
  bottom: 0;
}
footer p{
  color: white;
  text-shadow: 1px 1px 4px black;
}
.root{
  z-index: 1;

}
.App{
  margin-top:25vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.blob{
  user-select: none;
  text-align: center;
  align-self: center;
}

.barlow-condensed-regular{
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1{
  font-size: 8vh;
  color:white;
  text-shadow: 1px 1px 4px black;
}
input{
  font-family: "Barlow Condensed", sans-serif;
  height: 3vh;
  width: 40vh;
  height: 3vh;
  font-weight: 500;
  font-size: 20px;
}

.shorten-button{
  margin-top: 1vh;
  align-items: center;
  appearance: none;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: grey;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 3vh;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  z-index: 0;
  margin-left: 1vh;
  transition-duration: 0.125s;
}
.shorten-button:hover{
  transform: scale(1.05);
}
.shorten-button:active{
  transform: scale(0.95);
}

.shorten-button {
  border-radius: 4px;
  background-color: #f4511e;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
}

.shorten-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.shorten-button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.shorten-button:hover span {
  padding-right: 25px;
}

.shorten-button:hover span:after {
  opacity: 1;
  right: 0;
}
.result-div{
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 10px;
  border: #f4511e 1px solid;
  width: fit-content;
  margin: auto;
  margin-top: 2vh;
  border: 3px solid rgb(0, 183, 255);
  padding: 10px;
}
.link-div{
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: text;
}
.result-div p a{
  text-decoration: none; /* Remove underline */
  color: inherit;
  font-weight: bold;
  transition: all 0.25s;
}
.result-div p a:hover{
  color: rgb(0, 89, 255); /* Change color on hover/focus */
  font-weight: bold;
}
.ErrorUrlMessage{
  font-family: "Barlow Condensed", sans-serif;
  color:red;
  margin-top: 10px;
}
.logoGif{
  width: 10vh; /* Adjust width as needed */
  height: auto;
  -webkit-user-drag: none;
}
.title-div{
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: center; /* Center horizontally */
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  .title-div {
    flex-direction: column; /* Stack logo on top of the text on smaller screens */
  }

  .logoGif {
    margin-right: 0;
    margin-bottom: 0vh;
  }
}